import React from "react";

const Objectives = () => {
  return (
    <div>
      <div></div>
    </div>
  );
};
export default Objectives;
