import React from "react";

const Education = () => {
  return (
    <div>
      <div></div>
    </div>
  );
};
export default Education;
