import React from "react";

const Skills = () => {
  return (
    <div>
      <div></div>
    </div>
  );
};
export default Skills;
